html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
table {
  width: 80%;
  table-layout: fixed;
}
table tr th {
  text-align: left;
  background: gray;
  color: white;
}

/* Grid Wave */

canvas {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    center bottom,
    from(rgb(0 0 0 / 68%)),
    to(rgba(0, 0, 0, 0))
  );
}

.grid-wave {
  width: 100%;
  height: 100%;
  position: absolute;
  max-height: 1200px;
}

.grid-wave-container {
  position: relative;
  width: 100%;
  height: 110vh;
  min-height: 1000px;
  max-height: 1200px;
}

.grid-wave-container-2 {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 1000px;
  /* max-height: 1200px; */
  overflow-y: hidden;
}

.grid-wave-content {
  position: relative;
  min-height: 100vh;
  /* overflow-y: hidden; */
}
.grid-wave-content-main {
  position: relative;
  min-height: 100vh;
  /* overflow-y: hidden; */
}

@media all and (min-width: 600px) {
  .grid-wave-container {
    height: 110vh;
    max-height: 1500px;
  }
}

@media all and (max-height: 1100px) {
  .grid-wave-container {
    height: 1200px;
    /* max-height: 1000px; */
  }
}

/* @media all and (min-width: 600px) and (max-width: ) {
  .grid-wave-container {
    height: 120vh;
    max-height: 1500px;
  }
} */

:root {
  --blue-color-1: #ffffff;
}

.bg-blue-1 {
  background: var(--blue-color-1);
}

/* */

h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 71px;
  /* or 137% */

  color: #0b121c;
}
h2 {
  font-size: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 171%;
  /* identical to box height, or 24px */

  /* text-align: center; */

  color: #93a2a9;
}
h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 71px;
  /* or 137% */

  color: #0b121c;
}

.home_title {
  width: 420px;
  position: relative;
}
.home_subtitle {
  width: 30vw;
  position: relative;
}

.front_illustration {
  width: 70vw;
  max-width: 580px;
  height: auto;
}

@media all and (max-width: 900px) {
  .home_title {
    width: 90vw;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    font-size: 34px;
  }
  .home_subtitle {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    font-size: 18px;
  }
  .front_illustration {
    width: 100vw;
    max-width: 580px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media all and (max-width: 900px) and (min-width: 600px) {
  .home_title {
    font-size: 40px;
  }
  .home_subtitle {
    font-size: 24px;
  }
}

@media all and (max-width: 1200px) and (min-width: 900px) {
  .front_illustration {
    width: 50vw;
    max-width: 580px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .home_title {
    font-size: 46px;
  }
}

/* How it works*/
.how_it_works_background {
  width: 80%;
  height: auto;
  left: 50%;
  transform: translate(-50%, -50%);

  position: absolute;
}

.hiw_title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 56px;
  color: #ffffff;
  padding-top: 3%;
  text-align: center;
}

.hiw_subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  margin-top: 50px;

  color: #ffffff;
}

.hiw_p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 171%;
  /* or 21px */
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-bottom: 6vh;
  color: #93a2a9;
}

@media all and (max-width: 600px) {
  .hiw_title {
    padding-top: 50px;
  }
}

@media all and (max-width: 900px) {
  .hiw_subtitle {
    font-size: 24px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .hiw_p {
    font-size: 16px;
    width: 80%;
  }
  .hiw_background_box {
    background-color: #0b121c;
  }
}

.hiw_card {
  background: #152132;
  opacity: 0.85;
  border-radius: 10px;
  text-align: center;
  /* height: 30vh; */
  height: 230px;
}

.hiw_circle {
  background: #0b121c;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  top: 20px;
  position: relative;
  display: flex;
}
.hiw_image {
  margin: auto;
  display: block;
  width: 55%;
}

.hiw_label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-top: 40px;
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;

  color: #ffffff;
}

/* Why use? */

.eth {
  font-family: "Open Sans", sans-serif;
}

.why_use_title {
  margin-top: 10vh;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;

  width: 40vw;
  max-width: 600px;
  color: #0b121c;
}

.why_use_p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  max-width: 600px;
  width: 90%;

  color: #93a2a9;
}

.horizontal_dotted_line {
  border-bottom: 2px dashed #66738740;
  width: 20%;
}

@media all and (max-width: 500px) {
  .horizontal_dotted_line {
    width: 10%;
  }
}

.why_use_sale_item {
  margin-top: 15px;
  border-radius: 50px;
  border: 2px solid #66738736;
  width: 80%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#wus1 {
  width: 75%;
  max-width: 550px;
}
#wus2 {
  width: 85%;
  max-width: 650px;
}
#wus3 {
  width: 90%;
  max-width: 750px;
}
#wus4 {
  width: 87%;
  max-width: 670px;
}

@media all and (max-width: 1200px) {
  .why_use_title {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    text-align: center;
  }
  .why_use_p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* width: 80%; */
  }

  #wus1 {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  #wus2 {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  #wus3 {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  #wus4 {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}
.why_use_sale_left {
  margin-left: 5%;
}
.why_use_sale_right {
  margin-right: 5%;
}

.why_use_sale_label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  /* font-size: 20px; */
  font-size: clamp(14px, 1.4vw, 20px);
  line-height: 32px;
  max-width: 600px;
  color: #93a2a9;
}

.why_use_sale_emphasis {
  font-family: "Poppins";
  font-weight: bold;
  /* font-size: 20px; */
  font-size: clamp(14px, 1.4vw, 20px);
  line-height: 32px;
  /* or 200% */
  max-width: 600px;

  color: #0b121c;
}
.why_use_sale_emphasis2 {
  font-family: "Poppins";
  font-weight: bold;
  /* font-size: 24px; */
  font-size: clamp(16px, 2vw, 24px);
  line-height: 32px;
  /* or 200% */
  max-width: 600px;

  color: #0b121c;
}

.why_use_paperhand {
  margin-top: auto;
  margin-bottom: auto;
  width: 90%;
  max-width: 1000px;
}
.why_use_paperhand2 {
  width: 90%;
  max-width: 1000px;
}

@media all and (max-width: 500px) {
  .why_use_paperhand2 {
    width: 120%;
    margin-left: 40vw;
    max-width: 1000px;
  }
}

/* FAQ */

.FAQ_title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  /* identical to box height, or 156% */

  color: #ffffff;
  text-align: center;
}

.FAQp {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 50px;
  /* identical to box height, or 156% */

  color: #93a2a9;
  text-align: left;
}

.FAQp2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;
  /* identical to box height, or 156% */

  color: #93a2a9;
  text-align: left;
  padding-left: 40px;
  padding-right: 20px;
}

.FAQa {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 50px;
  /* identical to box height, or 156% */

  color: #93a2a9;
  text-align: left;
}
.FAQa2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;

  color: #93a2a9;
}

@media all and (max-width: 1200px) {
  .FAQp {
    font-size: 16px;
  }

  .FAQp2 {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 5px;
  }
  .FAQa {
    font-size: 16px;
  }

  .FAQa2 {
    font-size: 14px;
  }
}

/* wave */
.wave_background {
  width: 100%;
  min-height: 300px;
  position: absolute;
  /* margin-top: -10vh; */
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  border-radius: 20px;
}

.wave_block {
  /* position: absolute;
  left: 0;
  right: 0;
  text-align: center; */
  width: 80%;
  max-width: 1800px;
  position: absolute;
  margin-top: -10vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.wave_title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: white;
  position: relative;
  text-align: center;
  margin-top: 10vh;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

@media all and (max-width: 1200px) {
  .wave_title {
    margin-top: 5vh;
  }
}

@media all and (max-width: 600px) {
  .wave_title {
    margin-top: 5vh;
    font-size: 21px;
  }
}

/* Footer */
.footer_logo {
  position: relative;
  left: 25%;
}
.footer_logo_image {
  max-height: 50px;
  padding-left: 16px;
}

.footer_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #93a2a9;
  text-align: right;
  padding-right: 10vw;
}
.footer_socials {
  display: flex;
  justify-content: flex-end;
  padding-right: 10vw;
}

.footer_links {
  display: flex;
  padding-right: 10vw;
  justify-content: flex-end;
}

.footer_terms {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #93a2a9;
  text-align: right;
  padding-left: 40px;
}

.footer_socials_indiv {
  margin-left: 10px;
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
}
.footer_socials_indiv .img-top {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.footer_socials_indiv:hover .img-top {
  display: inline;
}

.img-normal,
.img-top {
  width: 50px;
  height: auto;
}

/* */
.NFT-Image {
  height: 200px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 5px;
}

.Diamondhanded-Image {
  height: 15vw;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
  display: block;
  border-radius: 5px;
}

.collection-Rankings-Image {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-left: 10px;
}

.logo {
  max-height: 40px;
  /* width: auto; */
}

.logo_navbar_home {
  max-height: 50px;
  left: 25%;
  position: relative;
}

.logo_navbar_image {
  max-height: 50px;
}

.header {
  font-size: 50px;
}

.eth-symbol {
  font-family: "Open Sans", sans-serif;
  color: white;
}

.menuLink {
  /* color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    */
  display: block;
  margin-left: min(5vw, 50px);
  text-decoration: none;
}

.submenus {
  color: black;
  text-decoration: none;
}

.NFT-Label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  /* identical to box height, or 156% */

  color: #ffffff;
  text-align: center;
}

p {
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

/** Home **/

#about {
  font-size: 40px;
}

/* https://www.w3schools.com/howto/howto_css_two_columns.asp */
/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}
.circle {
  border: 0.15em solid white;
  border-radius: 100%;
  height: 1.5em;
  width: 1.5em;
  text-align: center;
}

.circle h2 {
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: bold;
  font-family: sans-serif;
  color: white;
}

.steps {
  display: flex;
  margin-top: 0.5em;
}
.steps p {
  margin-left: 0.5em;
  font-size: 1em;
  margin-top: auto;
  margin-bottom: auto;
}

.frontImage {
  margin-top: 10%;
  max-width: 100%;
}

.paperhandImage {
  max-width: 100%;
}

.diamondHandButton {
  margin-top: 5%;
  /* text-align: center; */
}

.footnote {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 50px;
  /* identical to box height, or 156% */

  color: #93a2a9;
  text-align: center;
}

.footnoteA {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 50px;
  /* identical to box height, or 156% */

  color: #93a2a9;
  text-align: center;
}

/* Stats */
.stats_title {
  text-align: center;
}
.statsTable {
  cursor: pointer;
}

.statsLabel {
  text-align: center;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

@media all and (max-width: 900px) {
  .statsLabel {
    font-size: 18px;
  }
}

.spacer {
  margin-top: 40px;
}

.stats-switch-label {
  font-size: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  line-height: 171%;
  color: #93a2a9;
  text-align: center;
}

/* Profile */

.walletAddress {
  text-align: center;
  /* color: black; */
  margin-left: auto;
  margin-right: auto;
  overflow-wrap: break-word;
  width: 80%;
}

/* Start */

.specific_nft_h2 {
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}

.note {
  font-size: 15px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.confirmation_note {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
}

button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.NFT-Image-Start {
  height: auto;
  width: auto;
  max-width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-height: 240px;
}

.nft-collection-name {
  font-size: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 171%;
  /* identical to box height, or 24px */

  /* text-align: center; */

  color: #93a2a9;

  /* margin-top: auto; */
  text-align: center;
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}
.nft-name {
  font-size: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 171%;
  color: #0f121a;

  text-align: center;
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.p-shareablecard {
  font-size: 18px;
  color: "black";
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 172%;
}

/* Terms of Use */

.TOU_title {
}
.TOU_subtitle {
  color: black;
  font-weight: 600;
}
.TOU_p {
  color: black;
  line-height: 32px;
}

/* Mint */

.diamondPass {
  width: 60%;
  max-width: 1000px;
  border-radius: 25px;
}

@media all and (max-width: 900px) {
  .diamondPass {
    width: 60%;
  }
}

@media all and (max-width: 600px) {
  .diamondPass {
    width: 80%;
  }
}

/* Airdrop */
.claim_note {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  text-align: left;
  display: block;
  word-wrap: break-word;
}
